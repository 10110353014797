import { styled } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'
import { Controls, Player } from '@lottiefiles/react-lottie-player'

import { useGlossary, useTheme } from '@features/App/hooks'

const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(7),
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  [theme.breakpoints.down('md')]:{
    padding: theme.spacing(0),
    gap: theme.spacing(4),
    alignItems: 'center',
  }
}))

const TitleLabel = styled(Box)(({ theme }) => ({
  font: theme.font.rubik.title.medium,
  fontWeight: '600',
  color: theme.palette.gray[700],
  maxWidth: '65%',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    textAlign: 'center',
  },
}))

const Container = styled(Box)(({ theme }) => ({
  width: `min(calc(100% - ${theme.spacing(2 * 10)}), 1600px)`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(6),
  borderRadius: theme.spacing(1.5),
  backgroundColor: theme.palette.gray[100],
  boxShadow: theme.shadows.elevation.large,
  padding: `${theme.spacing(5)} ${theme.spacing(10)}`,
  paddingBottom: theme.spacing(15),
  [theme.breakpoints.down('md')]: {
    backgroundColor: theme.palette.gray[200],
    boxShadow: 'none',
    width: '100%',
    flexDirection: 'column',
    padding: `${theme.spacing(4)} ${theme.spacing(2)}`,
  },
}))

const GifContainer = styled(Box)(({ theme }) => ({
  height: '470px',
  width: '470px',
  alignContent: 'center',
  [theme.breakpoints.down('md')]:{
    width: '350px',
    height: '350px',
  }
}))

const View400 = ({ }) => {
  const { glossary } = useGlossary()
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  return <Root>
  <Container>
    <GifContainer>
      <Player
        autoplay
        loop
        src='/animations/400lottie.json'
        style={{ height: '100%', width: '100%' }}
      >
        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
      </Player>
    </GifContainer>
    <TitleLabel>
      {glossary('WeAreSorry')}
    </TitleLabel>
  </Container>
  </Root>
}

export default View400
