import Layout from './Layout'
import Nav from './Nav'
import Footer from './Footer'
import NavDesktop from './NavDesktop'
import NavMobile from './NavMobile'
import ProfileMenu from './ProfileMenu'

export default {
  Layout,
  Nav,
  Footer,
  NavDesktop,
  NavMobile,
  ProfileMenu,
}

export {
  Layout,
  Nav,
  Footer,
  NavDesktop,
  NavMobile,
  ProfileMenu,
}

