import { styled } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'
import Head from 'next/head'

import useGlossary from '@hooks/useGlossary'
import useTheme from '@hooks/useTheme'
import Nav from './Nav'
import Footer from './Footer'
import BottomMenu from './BottomMenu'

const MainContainer = styled(Box)(({ theme, background = null }) => ({
  backgroundColor: background ?? theme.palette.gray[200],
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    '&::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
    },
    '& *::-webkit-scrollbar': {
      width: '0px',
      height: '0px',
    },
  },
}))

const ViewContainer = styled(Box, {
  shouldForwardProp: propName => propName !== 'hideSearch',
})(({ theme, hideSearch, background = null }) => ({
  maxHeight: `calc(100vh - ${theme.spacing(hideSearch ? 8 : 9)})`,
  minHeight: `calc(100vh - ${theme.spacing(hideSearch ? 8 : 9)})`,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: background ?? theme.palette.gray[200],
  overflowX: 'hidden !important',
  overflowY: 'scroll',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flex: '1',
  },
}))

const Layout = ({
  children,
  hideFooter = false,
  hideSearch = false,
  showMenu = false,
  addMetadata = true,
  background = null,
}) => {
  const { theme } = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'))
  const { glossary } = useGlossary()

  return (
    <>
      {addMetadata && (
        <Head>
          <meta name='description' content={glossary('MetaDescriptionMain')} />
          <meta name='keywords' content='Stadibox, Boletos, Palco, Plateas, Conciertos en México, Eventos en México' />
          <meta property='og:title' content='Stadibox | Boletos, Palcos, Plateas, Conciertos en México' />
          <meta property='og:description' content={glossary('MetaDescriptionMain')} />
          <meta property='og:type' content='website' />
          <meta property='og:url' content='https://stadibox.com/' />
          <meta property='og:image' content='https://cdn.stadibox.com/U7fmwwgo4U/Logocover.png' />
        </Head>
      )}
      <MainContainer background={background}>
        <Nav hideSearch={hideSearch} />
        {/* <TawkMessengerReact */}
        {/*  propertyId='5990dc5f1b1bed47ceb04669' */}
        {/*  widgetId='default'/> */}
        <ViewContainer background={background} hideSearch={!isMd && hideSearch}>
          {children}
          {isMd && !hideFooter && <Footer />}
          {!isMd && showMenu && <BottomMenu />}
        </ViewContainer>
      </MainContainer>
    </>
  )
}

export default Layout
