
import View400 from '@features/ErrorPages/View400'
import { Layout } from '@features/Layout'

const Page400 = ({}) => {
  return <View400/>
}

Page400.getLayout = function getLayout(page) {
  return <Layout hideSearch hideFooter>{page}</Layout>
}

export default Page400
